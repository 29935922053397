<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item v-bind="validate">
        <SearchTable v-model:modelValue="inputValue" @ok="onChange" />
        <span v-if="validate" class="must">*</span>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import SearchTable from '../SearchTable'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    validate: {
      type: Object,
      default: () => null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'text'
    }
  },
  components: { SearchTable },
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e.id)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>

<style lang="less" scoped>
.must {
  position: absolute;
  top: 0;
  right: -10px;
  color: #f00;
  font-size: 18px;
  line-height: 32px;
}
</style>
